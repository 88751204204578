import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
           <h1>tesing from binary infura</h1>
    </div>
  );
}

export default App;
